import React from 'react';
import './QuemSomos.css';

export default function QuemSomos() {
  return (
    <section className='quemsomos'>
      <div className="container">
        <div>
          <h2>Quem somos</h2>
          <p>Com base na nossa vasta experiência na área contábil, estamos capacitados para fornecer serviços de excelência, atendendo às necessidades fiscais, trabalhistas e societárias dos nossos clientes. Nosso principal foco é auxiliar na gestão das empresas, oferecendo um planejamento eficiente para enfrentar os desafios tributários, com o objetivo de reduzir custos e garantir a conformidade legal.</p>
        </div>
        <div>
          <img src="/images/business-deal.svg" alt="Empresários fazendo acordo com aperto de mão" />
        </div>
      </div>
    </section>
  )
}
