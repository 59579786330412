import Footer from "./components/Footer";
import Header from "./components/Header";
import QuemSomos from "./components/QuemSomos";
import Servicos from "./components/Servicos";

function App() {
  return (
    <div>
      <Header />
      <main>
        <QuemSomos />
        <Servicos />
      </main>
      <Footer />
    </div>
  );
}

export default App;
