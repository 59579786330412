import React from "react";
import {
  BiLogoInstagramAlt,
  BiSolidEnvelope,
  BiSolidPhoneCall,
  BiSolidMap,
  BiUserCircle,
} from "react-icons/bi";
import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div>
          <h3>Contatos</h3>
          <ul className="footer__list">
            <li>
              <a href="tel:05134882485">
                <BiSolidPhoneCall size={18} /> 51 3488.2485
              </a>
            </li>
            <li>
              <a href="mailto:contato@integral-solucoes.com">
                <BiSolidEnvelope size={18} /> contato@integral-solucoes.com
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/integralcontabilidade_"
                target="_blank"
                rel="noreferrer"
              >
                <BiLogoInstagramAlt size={18} /> @integralcontabilidade_
              </a>
            </li>
          </ul>
        </div>

        <ul className="footer__list">
          <li className="footer__address">
            <BiSolidMap />
            <div>
              <p>Av. José Loureiro da Silva, 1067, sala 206</p>
              <p>Centro de Gravataí/RS</p>
            </div>
          </li>

          <li>
            <a
              href="https://onvio.com.br/"
              target="_blank"
              rel="external noopener noreferrer"
            >
              <BiUserCircle />
              Área de Funcionários
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
