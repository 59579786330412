import React from "react";
import { ReactComponent as Logo } from "./integral.svg";
import "./Header.css";

export default function Header() {
  return (
    <header>
      <div className="container">
        <div>
          <Logo />
        </div>

        <ul className="navbar">
          <li>
            <a
              href="https://onvio.com.br/clientcenter/company/5F3D505C93A049F18CA945F1030AF86A"
              target="_blank"
              rel="external 2 noopener noreferrer"
              className="btn"
            >
              Área do Cliente
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}
