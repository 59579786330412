import React from 'react';
import './Servicos.css';

export default function Servicos() {
  return (
    <section className='servicos'>
      <div className="container">
        {/* <div>
          <img src="/images/claudia.jpg" alt="Claudia, contadora, lendo um livro" className='servicos__imagem' />
        </div> */}
        <div>
          <h2 className='servicos__subtitulo'>Serviços <span>para sua empresa</span></h2>
          <ul className='servicos__lista'>
            <li>Abertura e encerramento de empresas;</li>
            <li>Assessoria mensal nas áreas contábil, fiscal e pessoal;</li>
            <li>Perícia extrajudicial e assistência técnica;</li>
            <li>Planejamento tributário;</li>
            <li>Regularização fiscal.</li>
          </ul>
        </div>
      </div>
    </section>
  )
}
